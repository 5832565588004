import { Suspense, useEffect, lazy } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import AOS from "aos";

import "./assets/css/margins-paddings.css";
import "./assets/css/aos.css";
import "./globalStyles.css";
import "./i18n";

// const HomePage = lazy(() => import("./pages/home/index"));
const LandingPage = lazy(() => import("./pages/landing"));
const ServicePage = lazy(() => import("./pages/services"));
const AboutPage = lazy(() => import("./pages/about"));
const ContactPage = lazy(() => import("./pages/contact"));
const CareerPage = lazy(() => import("./pages/career"));

const PrivacyPolicyPage = lazy(() => import("./pages/privacyPolicy"));
const TermsConditionsPage = lazy(() => import("./pages/termsConditions"));
const WorkplacePolicyPage = lazy(() => import("./pages/workplacePolicy"));
const VaccinePolicyPage = lazy(() => import("./pages/vaccinePolicy"));
// import PortfolioPage from "./components/portfolioPage";

const ServiceLayout = lazy(() => import("./pages/services/serviceLayout"));
const EnergyAndPowerSolutions = lazy(() => import("./pages/services/energyAndPowerSolutions"));
const Health = lazy(() => import("./pages/services/health"));
const IndustrialAutomation = lazy(() => import("./pages/services/industrialAutomation"));
const IndustrialMachines = lazy(() => import("./pages/services/industrialMachines"));
const IndustrialSafety = lazy(() => import("./pages/services/industrialSafety"));
const IndustrialSolutions = lazy(() => import("./pages/services/industrialSolutions"));
const Infrastructural = lazy(() => import("./pages/services/infrastructural"));
const OilAndGasFields = lazy(() => import("./pages/services/oilAndGasFields"));
const ItSolutions = lazy(() => import("./pages/services/itSolutions"));
const RawMaterials = lazy(() => import("./pages/services/rawMaterials"));
const SmartLogistics = lazy(() => import("./pages/services/smartLogistics"));
const SurveillanceSolutions = lazy(() => import("./pages/services/surveillanceSolutions"));
const TradeSolutions = lazy(() => import("./pages/services/tradeSolutions"));

// Loading component for Suspense fallback
const LoadingSpinner = () => (
  <div className="d-flex justify-content-center align-items-center py-4">
    <Spinner animation="border" variant="primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

// ScrollToTop component with performance optimization
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
    return () => clearTimeout(timeoutId);
  }, [pathname]);
  return null;
}

// Router configuration with routes grouped by category
const serviceRoutes = [
  { path: "energyAndPowerSolutions", element: <EnergyAndPowerSolutions /> },
  { path: "health", element: <Health /> },
  { path: "industrialAutomation", element: <IndustrialAutomation /> },
  { path: "industrialMachines", element: <IndustrialMachines /> },
  { path: "industrialSafety", element: <IndustrialSafety /> },
  { path: "industrialSolutions", element: <IndustrialSolutions /> },
  { path: "infrastructural", element: <Infrastructural /> },
  { path: "oilAndGasFields", element: <OilAndGasFields /> },
  { path: "itSolutions", element: <ItSolutions /> },
  { path: "rawMaterials", element: <RawMaterials /> },
  { path: "smartLogistics", element: <SmartLogistics /> },
  { path: "surveillanceSolutions", element: <SurveillanceSolutions /> },
  { path: "tradeSolutions", element: <TradeSolutions /> },
];

function App() {
  // Initialize AOS with performance optimizations
  useEffect(() => {
    const initAOS = async () => {
      AOS.init({
        once: true, // Only animate elements once
        disable: 'mobile', // Disable on mobile devices
        startEvent: 'DOMContentLoaded', // Initialize AOS on DOMContentLoaded
        offset: 120,
        delay: 0,
        duration: 400,
      });
    };

    initAOS();

    return () => {
      // Cleanup AOS on component unmount
      AOS.refresh();
    };
  }, []);

  return (
    <div className="section-wrapper">
      <div id="preLoader"></div>
      <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            {/* Main routes */}
            <Route path="/" element={<LandingPage />} />
            <Route path="services" element={<ServicePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="career" element={<CareerPage />} />

            {/* Policy routes */}
            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="terms-and-conditions" element={<TermsConditionsPage />} />
            <Route path="workplace-policies" element={<WorkplacePolicyPage />} />
            <Route path="vaccine-policy" element={<VaccinePolicyPage />} />

            {/* Service routes */}
            <Route element={<ServiceLayout />}>
              {serviceRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
